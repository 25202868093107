.loading-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loading-image {
    animation: growShrink 2s infinite alternate; /* Animation duration: 2 seconds, infinite loop, alternate direction */
    animation-delay: 5s;
  }
  
  @keyframes growShrink {
    0% {
      transform: scale(1); /* Initial size */
    }
    50% {
      transform: scale(1.2); /* Grow to 120% size */
    }
    100% {
      transform: scale(1); /* Shrink back to original size */
    }
  }
  