/* Import all font families from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
*{
  padding: 0%;
  margin: 0%;
}

html, body{
  overflow-x: hidden;
  width: 100%;
}